body {
}

div.bp3-input {
  height: auto;
  line-height: 1.3;
  padding: 8px 12px;
}

.container {
  margin: 20px auto;
  max-width: 640px;
}
